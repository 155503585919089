import { useMotionValue } from "framer-motion";
import React, { useCallback, useEffect } from "react";
import { lerp } from "../utils";
import { useWindowDimensions } from "./windowDimensions";

type ClientPosition = {
  clientX: number;
  clientY: number;
  movementX: number;
  movementY: number;
};
type Position = {
  x: number;
  y: number;
};

let previousTouch: ClientPosition | null = null;
const getClientPosition = (e: TouchEvent | MouseEvent): ClientPosition => {
  if ("touches" in e) {
    const touch = {
      clientX: e.touches[0].clientX,
      clientY: e.touches[0].clientY,
      movementX:
        e.touches[0].clientX - (previousTouch ? previousTouch.clientX : 0),
      movementY:
        e.touches[0].clientY - (previousTouch ? previousTouch.clientY : 0),
    };

    previousTouch = touch;

    return touch;
  }

  return {
    clientX: e.clientX,
    clientY: e.clientY,
    movementX: e.movementX,
    movementY: e.movementY,
  };
};

interface DragMapProps {
  onStart?: () => void;
  onEnd?: () => void;
  onMove: (position: Position) => void;
}

const useDragMap = ({ onMove, onStart, onEnd }: DragMapProps) => {
  const { width, height } = useWindowDimensions();

  const xValue = useMotionValue(0);
  const yValue = useMotionValue(0);

  const onDragStart = useCallback(
    (position: ClientPosition) => {
      const startX = position.clientX;
      const startY = position.clientY;

      let started = false;

      const onDragMove = (e: MouseEvent | TouchEvent) => {
        e.preventDefault();
        const { clientX, clientY, movementX, movementY } = getClientPosition(e);

        if (
          (Math.abs(startX - clientX) > 10 ||
            Math.abs(startY - clientY) > 10) &&
          !started
        ) {
          document.body.classList.add("map-lock");

          started = true;

          if (onStart) {
            onStart();
          }
        }

        xValue.set(-movementX);
        yValue.set(-movementY);

        // onMove({
        //   x: -movementX,
        //   y: -movementY,
        // });
      };

      const onDragEnd = () => {
        window.removeEventListener("mousemove", onDragMove);
        window.removeEventListener("touchmove", onDragMove);
        window.removeEventListener("mouseup", onDragEnd);
        window.removeEventListener("touchend", onDragEnd);
        document.body.classList.remove("map-lock");
        if (onEnd) {
          previousTouch = null;
          onEnd();

          const rampDown = () => {
            xValue.set(lerp(xValue.get(), 0, 0.2));
            yValue.set(lerp(yValue.get(), 0, 0.2));

            if (
              Math.abs(xValue.get()) >= 0.5 ||
              Math.abs(yValue.get()) >= 0.5
            ) {
              setTimeout(() => {
                rampDown();
              }, 50);
            } else {
              xValue.set(0);
              yValue.set(0);
            }
          };

          rampDown();
        }
      };

      window.addEventListener("mousemove", onDragMove, { passive: false });
      window.addEventListener("touchmove", onDragMove, { passive: false });
      window.addEventListener("mouseup", onDragEnd);
      window.addEventListener("touchend", onDragEnd);
    },
    [height, onEnd, onStart, width]
  );

  const onDragInitiate = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
    ) => {
      e.preventDefault();

      onDragStart(getClientPosition(e.nativeEvent));
    },
    [onDragStart]
  );

  useEffect(() => {
    let frameId = -1;
    const update = () => {
      if (xValue.get() !== 0 || yValue.get() !== 0) {
        onMove({
          x: xValue.get(),
          y: yValue.get(),
        });
      }

      frameId = requestAnimationFrame(update);
    };

    update();

    return () => {
      cancelAnimationFrame(frameId);
    };
  }, [onMove]);

  return {
    listeners: {
      onMouseDown: onDragInitiate,
      onTouchStart: onDragInitiate,
    },
  };
};

export { useDragMap };
