import React, { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { IGatsbyImageData } from "gatsby-plugin-image";

import * as styles from "./ExploreMapVideo.module.scss";

interface ExploreMapVideoProps {
  id: string;
  src: string;
  image: IGatsbyImageData;
  dimensions: {
    width: number;
    height: number;
  };
  className?: string;
  onRef?: (id: string, el: HTMLElement | null) => void;
  show?: boolean;
}

const ExploreMapVideo: React.FC<ExploreMapVideoProps> = ({
  id,
  src,
  image,
  dimensions,
  className,
  onRef,
  show,
}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 250);
  }, []);

  const ratio = useMemo(() => {
    return (dimensions.height || 1) / (dimensions.width || 1);
  }, [dimensions]);

  const onVideoLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  const onForwardRef = useCallback(
    (el: HTMLElement | null) => {
      onRef && onRef(id, el);
    },
    [onRef]
  );

  return (
    <div
      data-id={id}
      ref={onForwardRef}
      className={classNames(
        styles.outer,
        { [styles.loaded]: loaded },
        className
      )}
      style={{ backgroundColor: image.backgroundColor }}
    >
      <div
        className={styles.inner}
        style={{ "--image-ratio": ratio } as React.CSSProperties}
      >
        {show && (
          <video
            onCanPlay={onVideoLoad}
            src={src}
            muted
            autoPlay
            loop
            playsInline
          />
        )}
      </div>
    </div>
  );
};

export { ExploreMapVideo };
