import { Link } from "gatsby";
import classNames from "classnames";
import {
  IGatsbyImageData,
  StaticImage,
  GatsbyImage,
} from "gatsby-plugin-image";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDragMap } from "../../utils/hooks/dragMap";
import { useMediaQuery } from "../../utils/hooks/mediaQuery";
import { useMoveMap } from "../../utils/hooks/moveMap";
import { useWindowDimensions } from "../../utils/hooks/windowDimensions";
import { clamp, listen, mapRange } from "../../utils/utils";
import { useSiteContext } from "../SiteContext";

import * as styles from "./ExploreMap.module.scss";
import { ExploreMapImage } from "./ExploreMapImage";
import { ExploreMapVideo } from "./ExploreMapVideo";

type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
};

interface ExploreItem extends Position {
  project: {
    slug: {
      current: string;
    };
    date: string;
    title: string;
    categories: Array<{
      title: string;
      color: string;
    }>;
    mainImage: {
      asset: {
        gatsbyImageData: IGatsbyImageData;
        mimeType: string;
        url: string;
      };
    };
    // mainVideo?: {
    //   asset: {
    //     url: string;
    //   };
    // };
    mainVideoFile?: {
      url: string;
      poster: string;
      width: number;
      height: number;
    };
  };
}

interface ExploreMapProps {
  items: ExploreItem[];
  spreadWidth: number;
  spreadHeight: number;
  minColumn: number;
  minRow: number;
  className?: string;
}

const incrementPosition = (
  el: HTMLDivElement,
  position: Position,
  width: number,
  height: number,
  spreadWidth: number,
  spreadHeight: number
) => {
  const xo = parseInt(el.style.getPropertyValue("--x-pos") || "0");
  const yo = parseInt(el.style.getPropertyValue("--y-pos") || "0");

  const newX = clamp(
    xo + position.x,
    Math.min(-(spreadWidth / 2) + width * 0.25, -(width * 0.25)),
    Math.max(spreadWidth / 2 - width * 0.25, width * 0.25)
  );
  const newY = clamp(
    yo + position.y,
    Math.min(-(spreadHeight / 2) + height * 0.25, -(height * 0.25)),
    Math.max(spreadHeight / 2 + height * 0.25, height * 0.5)
  );

  el.style.setProperty("--x-pos", `${newX}px`);
  el.style.setProperty("--y-pos", `${newY}px`);
};

const ExploreMap: React.FC<ExploreMapProps> = ({
  items,
  spreadWidth,
  spreadHeight,
  minRow,
  minColumn,
  className,
}) => {
  const [visible, setVisible] = useState([]);

  const { introMode, dismissIntroMode, inMenu } = useSiteContext();

  const { width, height } = useWindowDimensions();
  const [isDragging, setIsDragging] = useState(false);
  const mapRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef(-1);

  const ioRef = useRef<null | IntersectionObserver>(null);

  const isTablet = useMediaQuery("screen and (min-width: 768px)");
  const isPointer = useMediaQuery("(pointer: coarse)");

  const onDragStart = useCallback(() => {
    clearTimeout(timerRef.current);
    setIsDragging(true);
  }, []);
  const onDragEnd = useCallback(() => {
    clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      setIsDragging(false);
    }, 1000);
  }, []);
  const onDragMove = useCallback(
    (position) => {
      dismissIntroMode();
      incrementPosition(
        mapRef.current,
        position,
        width,
        height,
        !isTablet ? spreadWidth * 0.55 : spreadWidth * 0.75,
        !isTablet ? spreadHeight * 0.5 : spreadHeight * 0.75
      );
    },
    [width, height, spreadWidth, spreadHeight, isTablet]
  );

  const { listeners } = useDragMap({
    onStart: onDragStart,
    onEnd: onDragEnd,
    onMove: onDragMove,
  });

  const { listeners: movementListeners } = useMoveMap({
    disabled: inMenu || isDragging || isPointer,
    onMove: onDragMove,
  });

  const onRef = useCallback((el: HTMLDivElement | null) => {
    if (el) {
      mapRef.current = el;
    }
  }, []);

  useEffect(() => {
    if (isDragging) {
      dismissIntroMode();
    }
  }, [isDragging]);

  const onIntersect = useCallback<
    (entries: IntersectionObserverEntry[]) => void
  >(
    (entries) => {
      console.log(entries);
      const visible = entries.filter((entry) => entry.isIntersecting);

      setVisible((state) => {
        return [
          ...new Set([
            ...state,
            ...visible.map((item) => (item.target as HTMLElement).dataset.id),
          ]),
        ];
      });
    },
    [setVisible]
  );

  const onObserve = useCallback((id: string, el: HTMLElement | null) => {
    if (el) {
      if (!ioRef.current) {
        ioRef.current = new IntersectionObserver(onIntersect);
      }
      ioRef.current.observe(el);
    }
  }, []);

  useEffect(() => {
    if (!ioRef.current) {
      ioRef.current = new IntersectionObserver(onIntersect);
    }

    return () => {
      ioRef.current.disconnect();
    };
  }, [onIntersect]);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  // const sizes = useMemo(() => {
  //   const box = {
  //     left: Math.min(-(spreadWidth / 2) + width * 0.25, -(width * 0.25)),
  //     right: Math.max(spreadWidth / 2 - width * 0.25, width * 0.25),
  //     top: Math.min(-(spreadHeight / 2) + height * 0.25, -(height * 0.25)),
  //     bottom: Math.max(spreadHeight / 2 + height * 0.25, height * 0.5),
  //   };

  //   return {
  //     ...box,
  //     width: Math.abs(box.left) + spreadWidth + box.right,
  //     height: Math.abs(box.top) + box.bottom,
  //   };
  // }, [spreadHeight, spreadWidth, width, height]);

  return (
    <div
      ref={onRef}
      {...(isPointer ? listeners : {})}
      className={classNames(styles.viewport, className)}
    >
      <div
        className={styles.plate}
        style={
          {
            "--total-columns": spreadWidth,
            "--total-rows": spreadHeight,
            // "--plate-left": sizes.left,
            // "--plate-top": sizes.top,
            // "--plate-width": sizes.width,
            // "--plate-height": sizes.height,
          } as React.CSSProperties
        }
      >
        {items.map((item, idx) => {
          const category = item.project.categories[0] || {
            title: "Project",
            color: "blue",
          };

          return (
            <Link
              to={`/project/${item.project.slug.current}` || "/"}
              key={item.project.slug.current}
              className={styles.item}
              style={
                {
                  "--x": item.x + Math.abs(minColumn),
                  "--y": item.y + Math.abs(minRow),
                } as React.CSSProperties
              }
              state={{
                fromHome: true,
              }}
            >
              {item.project.mainVideoFile && (
                <ExploreMapVideo
                  id={item.project.slug.current}
                  src={item.project.mainVideoFile.url}
                  className={styles.media}
                  image={item.project.mainImage.asset.gatsbyImageData}
                  dimensions={{
                    width: item.width,
                    height: item.height,
                  }}
                  onRef={onObserve}
                  show={visible.includes(item.project.slug.current)}
                />
              )}
              {item.project.mainImage && !item.project.mainVideoFile && (
                <ExploreMapImage
                  id={item.project.slug.current}
                  className={styles.media}
                  image={item.project.mainImage.asset.gatsbyImageData}
                  url={item.project.mainImage.asset.url}
                  onRef={onObserve}
                  show={visible.includes(item.project.slug.current)}
                />
              )}

              <span className={styles.info}>
                <span className={classNames(styles.dot, [category.color])} />
                {/* <span className={classNames(styles.type, [category.color])}>
                  {category.title}
                </span> */}
                <span className={styles.title}>
                  {item.project.title}
                  <br />
                  {item.project.date}
                </span>
              </span>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export { ExploreMap };
