import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { mapRange } from "../utils";
import { useMediaQuery } from "./mediaQuery";
import { useWindowDimensions } from "./windowDimensions";

const LOWER = 0.35;
const HIGHER = 0.65;
const MAX_SPEED = 15;

type Position = {
  x: number;
  y: number;
};

interface MoveMapProps {
  onMove: (position: Position) => void;
  lowerBound?: number;
  higherBound?: number;
  disabled?: boolean;
}

const useMoveMap = ({
  onMove,
  lowerBound = LOWER,
  higherBound = HIGHER,
  disabled,
}: MoveMapProps) => {
  const { width, height } = useWindowDimensions();
  const isPointer = useMediaQuery("(pointer: coarse)");

  const [active, setActive] = useState(false);

  const xDirection = useRef(0);
  const yDirection = useRef(0);
  const lastXDirection = useRef(0);
  const lastYDirection = useRef(0);

  const onPointerMove = useCallback(
    (e) => {
      const x = mapRange(e.clientX, 0, width, 0, 1);
      const y = mapRange(e.clientY, 0, height, 0, 1);

      if (x < lowerBound) {
        xDirection.current = mapRange(x, 0, lowerBound, -MAX_SPEED, 0);
      } else if (x > higherBound) {
        xDirection.current = mapRange(x, higherBound, 1, 0, MAX_SPEED);
      } else {
        xDirection.current = 0;
      }

      if (y < lowerBound) {
        yDirection.current = mapRange(y, 0, lowerBound, -MAX_SPEED, 0);
      } else if (y > higherBound) {
        yDirection.current = mapRange(y, higherBound, 1, 0, MAX_SPEED);
      } else {
        yDirection.current = 0;
      }
    },
    [width, height]
  );

  useEffect(() => {
    if (isPointer) {
      return;
    }

    document.addEventListener("mousemove", onPointerMove);

    return () => {
      document.removeEventListener("mousemove", onPointerMove);
    };
  }, [onPointerMove, isPointer]);

  useEffect(() => {
    // if (!width || !height || !active || disabled) {
    if (!width || !height || disabled) {
      return;
    }

    let frameid;
    let shouldRun = true;
    const update = () => {
      if (xDirection.current !== 0 || yDirection.current !== 0) {
        onMove({
          x: xDirection.current,
          y: yDirection.current,
        });
      }

      lastXDirection.current = xDirection.current;
      lastYDirection.current = yDirection.current;

      if (shouldRun) {
        frameid = requestAnimationFrame(update);
      }
    };

    update();

    return () => {
      cancelAnimationFrame(frameid);
      shouldRun = false;
    };
  }, [width, height, active, disabled]);

  return {
    listeners: {
      onMouseEnter: () => setActive(true),
      onMouseLeave: () => setActive(false),
      onMouseMove: onPointerMove,
    },
  };
};

export { useMoveMap };
