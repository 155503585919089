import React, { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { getImage, getSrc, IGatsbyImageData } from "gatsby-plugin-image";

import * as styles from "./ExploreMapImage.module.scss";
import { loadImage } from "../../../utils/promises";
import { builder } from "../../../utils/utils";

interface ExploreMapImageProps {
  id: string;
  image: IGatsbyImageData;
  url: string;
  className?: string;
  onRef?: (id: string, el: HTMLElement | null) => void;
  show?: boolean;
}

const ExploreMapImage: React.FC<ExploreMapImageProps> = ({
  id,
  image,
  url,
  className,
  onRef,
  show,
}) => {
  const [loaded, setLoaded] = useState(false);

  const ratio = useMemo(() => {
    return image.height / image.width;
  }, [image]);

  const onImageLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  const imageUrl = useMemo(() => {
    return builder.image(url).quality(80).width(550).url();
  }, [url]);

  const onForwardRef = useCallback(
    (el: HTMLElement | null) => {
      onRef && onRef(id, el);
    },
    [onRef]
  );

  return (
    <div
      data-id={id}
      ref={onForwardRef}
      className={classNames(
        styles.outer,
        { [styles.loaded]: loaded },
        className
      )}
      style={{ backgroundColor: image.backgroundColor }}
    >
      <div
        className={styles.inner}
        style={{ "--image-ratio": ratio } as React.CSSProperties}
      >
        {show && (
          <img src={imageUrl} alt="" loading="lazy" onLoad={onImageLoad} />
        )}
      </div>
    </div>
  );
};

export { ExploreMapImage };
