import React, { useMemo } from "react";
import { ExploreMap } from "../components/ExploreMap";
import * as styles from "../styles/pages/Home.module.scss";
import classNames from "classnames";
import { useSiteContext } from "../components/SiteContext";
import { Icon } from "../components/Common/Icon";

import { graphql, PageProps } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { useMediaQuery } from "../utils/hooks/mediaQuery";
import { SEO } from "../components/SEO";

// const items = new Array(50).fill(0).map((v, idx, arr) => {
//   const isVideo = Math.random() > 0.8;

//   const { x, y } = getCoords(arr.filter((i) => typeof i.x !== "undefined"));

//   return {
//     image: isVideo ? undefined : randomArray(IMAGES),
//     video: isVideo ? TestVideo : undefined,
//     to: Math.random() > 0.5 ? "/project/neon" : "/project/fuzzy",
//     x,
//     y,
//   };
// });

interface ImageQueryData {
  extremities: {
    maxX: number;
    maxY: number;
    minX: number;
    minY: number;
    spreadX: number;
    spreadY: number;
  };
  files: {
    edges: Array<{
      node: {
        x: number;
        y: number;
        width: number;
        height: number;
        project: {
          title: string;
          slug: {
            current: string;
          };
          date: string;
          categories: Array<{
            title: string;
            color: string;
          }>;
          mainImage: {
            asset: {
              gatsbyImageData: IGatsbyImageData;
              mimeType: string;
              url: string;
            };
          };
          mainVideoFile?: {
            url: string;
            width: number;
            height: number;
            poster: string;
          };
        };
      };
    }>;
  };
}

// markup
const IndexPage: React.FC<PageProps<ImageQueryData>> = ({ data }) => {
  const { introMode } = useSiteContext();

  const isPointer = useMediaQuery("(pointer: coarse)");

  return (
    <>
      <SEO title="Home" />
      <div
        className={classNames(styles.container, { [styles.hide]: !introMode })}
      >
        <div className={styles.intro}>
          <span className={styles.introSubtitle}>Explore the</span>
          <span className={styles.introTitle}>World of Jon Burgerman</span>

          <span className={styles.introInstructions}>
            <Icon name="move" />{" "}
            {isPointer
              ? "Drag around to explore"
              : "Move towards the edges with your cursor"}
          </span>
        </div>
        <ExploreMap
          className={styles.map}
          items={data.files.edges.map(({ node }) => {
            return {
              ...node,
              project: node.project,
            };
          })}
          spreadWidth={data.extremities.spreadX}
          spreadHeight={data.extremities.spreadY}
          minColumn={data.extremities.minX}
          minRow={data.extremities.minY}
        />
      </div>
    </>
  );
};

export const query = graphql`
  # query ImageQuery {
  #   files: allFile(
  #     filter: { absolutePath: { regex: "/scripts/" } }
  #     limit: 100
  #   ) {
  #     edges {
  #       node {
  #         childImageSharp {
  #           gatsbyImageData(width: 500)
  #         }
  #       }
  #     }
  #   }
  # }

  query ImageQuery {
    extremities: mapLayoutExtremities {
      maxX
      maxY
      minX
      minY
      spreadX
      spreadY
    }
    files: allMapLayout(filter: { project: { id: { ne: null } } }) {
      edges {
        node {
          height
          width
          x
          y
          project {
            slug {
              current
            }
            title
            categories {
              title
              color
            }
            date(formatString: "YYYY")
            mainImage {
              asset {
                gatsbyImageData(width: 500, formats: [JPG, WEBP])
                mimeType
                url
              }
            }
            mainVideoFile {
              width
              height
              url
              poster
            }
            # mainVideo {
            #   asset {
            #     url
            #   }
            # }
          }
        }
      }
    }
  }
`;

export default IndexPage;
